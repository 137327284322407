import React from "react";
import {backgrounds} from "../../assets";


export const AboutSection = () => {
  
  return(
    <div className="section section-x tc-bunker">
      <div className="container">
        <div className="row justify-content-between align-items-center gutter-vr-30px">
          <div className="col-xl-7 col-lg-6">
            <div className="image-block">
              <img src={backgrounds.header} alt=""/>
            </div>
          </div>
          <div className="col-xl-5 col-lg-6">
            <div className="text-block text-box-ml mtm-15">
              <h2>We help clients to create Digital amazing experience. </h2>
              <p className="lead tc-light">We can help you to unlock opportunity by creating human-centered
                products.</p>
              <p>Our designers and engineers know collaboration is the essence of any project. It’s a simple philosophy
                we followed for nearly two decades. And it delivers results.</p>
              <a href="dallas-dark-about.html" className="btn">About us</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
};
