import Constants from './constants';
/**
 * GET TEAM
 */
export const _getTeam = {
  pending: () => ({
    type: Constants.GET_TEAM_PENDING,
  }),
  success: data => ({
    type: Constants.GET_TEAM_SUCCESS,
    payload: data,
  }),
  error: err => ({
    type: Constants.GET_TEAM_ERROR,
    payload: err,
  }),
};
