import * as api from './api';
import * as actions from './actionCreators';

export const getProjects = () => dispatch => {
  dispatch(actions._getProjects.pending());
  api
    .getProjects()
    .then( res => {
        dispatch(actions._getProjects.success(res.body));
      }
    )
    .catch(
      err => {
        dispatch(actions._getProjects.error(err));
      }
    );
};

export const getCategories = () => dispatch => {
  dispatch(actions._getCategories.pending());
  api
    .getCategories()
    .then( res => {
        dispatch(actions._getCategories.success(res.body));
      }
    )
    .catch(
      err => {
        dispatch(actions._getCategories.error(err));
      }
    );
};

export const setCurrentProject = (project) => dispatch => {
  dispatch(actions._setCurrentProject.pending());
  dispatch(actions._setCurrentProject.success(project));
};

