import React from "react";
import {images} from "../../assets";
import Slider from "react-slick"

function SampleNextArrow(props) {
  const {className, style, onClick} = props
  return (
    <div
      className={className}
      style={{...style, display: 'block'}}
      onClick={onClick}
    >
      <i className='icon ti ti-angle-right'/>
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{...style, display: 'block'}}
      onClick={onClick}
    >
      <i className='icon ti ti-angle-left'/>
    </div>
  );
}

export const TestimonialsSection = () => {
  let settings = {
    dots: true,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow/>,
    prevArrow: <SamplePrevArrow/>
  }
  
  return(
    <div className="section section-x tc-bunker">
      <div className="container">
        <div className="row justify-content-center ">
          <div className="col-lg-8 text-center">
            <div className="section-head section-md">
              <h5 className="heading-xs dash dash-both">Testimonial</h5>
              <h2>What clients say about Genox</h2>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-10">
            <div className="tes-s1">
              <div className="has-carousel" data-items="1" data-loop="true" data-dots="true" data-auto="true"
                   data-navs="true" data-margin="30">
                <Slider {...settings}>
                <div className="tes-item">
                  <div className="row">
                    <div className="col-md-5">
                      <div className="tes-thumb">
                        <div className="bg-image">
                          <img src={images.client} alt=""/>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-7">
                      <div className="tes-block tc-light bg-primary">
                        <div className="tes-content">
                          <p className="tes-title u-cap"><strong>They are great agency</strong></p>
                          <p className="lead">I recently hired Genox to develop a new version of my most popular website
                            and I’m extremely happy with their work. </p>
                        </div>
                        <div className="author-con">
                          <h6 className="author-name t-u">Mike Andrew</h6>
                          <p>CEO - Philandropia</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="tes-item">
                  <div className="row">
                    <div className="col-md-5">
                      <div className="tes-thumb">
                        <div className="bg-image">
                          <img src={images.client} alt=""/>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-7">
                      <div className="tes-block tc-light bg-primary">
                        <div className="tes-content">
                          <p className="tes-title u-cap"><strong>They are great agency</strong></p>
                          <p className="lead">We love working with Genox Not only do they create beautiful, interactive
                            but they are also extremely helpful and amazing.</p>
                        </div>
                        <div className="author-con">
                          <h6 className="author-name t-u">MARINA SHOVA</h6>
                          <p>CEO - Art policy</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="tes-item">
                  <div className="row">
                    <div className="col-md-5">
                      <div className="tes-thumb">
                        <div className="bg-image">
                          <img src={images.client} alt=""/>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-7">
                      <div className="tes-block tc-light bg-primary">
                        <div className="tes-content">
                          <p className="tes-title u-cap"><strong>They are great agency</strong></p>
                          <p className="lead">These guys are legit. I’ll never hire another agency as long as I’m
                            working. They are also extremely helpful and amazing.</p>
                        </div>
                        <div className="author-con">
                          <h6 className="author-name t-u">HYPER MACK</h6>
                          <p>CEO - Hellozen Bulk</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
