import React, {Component} from 'react';
import {backgrounds, images} from "../../assets";
import Banner from "../../components/Banner";

class About extends Component {
  
  render() {
    return (
      <div>
        <Banner title="Specialists in Digital Solutions" image={backgrounds.singleProject}/>
  
        <div className="section section-x tc-bunker">
          <div className="container">
            <div className="row gutter-vr-30px">
              <div className="col-md-6">
                <div className="text-block pr-2rem">
                  <h5 className="heading-xs dash t-u">We Are</h5>
                  <h2>Genox is a bold-thinking digital agency working for leading brands worldwide.</h2>
                  <p className="lead">It’s not just our track record, the efficiency of our process and the quality of
                    our products. It’s the secret ingredient that makes it all happen: the bravery of our people.</p>
                </div>
              </div>
              <div className="col-md-6 tc-grey">
                <div className="text-block">
                  <div className="row gutter-vr-20px">
                    <div className="col-12 col-md-12 col-lg-6">
                      <div className="text-box project-box-pad bg-secondary h-full">
                        <h4>What we do</h4>
                        <p>We work at the frontier of interactive development and design. We are highly skilled and
                          happily take on complex technical challenges.</p>
                      </div>
                    </div>
                    <div className="col-12 col-md-12 col-lg-6">
                      <div className="text-box project-box-pad bg-secondary h-full">
                        <h4>Culture</h4>
                        <p>A friendly, ambitious team in a great office space makes Uppercase an awesome place to work.
                          We believe having fun leads to better results dadcation.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="section section-x tc-bunker">
          <div className="container">
            <div className="row justify-content-end">
              <div className="col-md-10 col-lg-5">
                <div className="text-block bg-secondary block-pad-80">
                  <h5 className="heading-xs dash">Why Choose us</h5>
                  <h2>We love to make a difference by creating digital experiences that simplify and enhance value.</h2>
                  <a href="dallas-dark-services.html" className="btn">Our Services</a>
                </div>
              </div>
            </div>
          </div>

          <div className="bg-image bg-fixed">
            <img src={backgrounds.singleProject} alt=""/>
          </div>
        </div>
  
        <div className="section section-counter-m tc-bunker">
          <div className="container">
            <div className="row align-items-center gutter-vr-30px justify-content-center">
              <div className="col-md-3 col-sm-6 col-6">
                <div className="counter">
                  <div className="counter-icon">
                    <em className="icon ti-dropbox"></em>
                  </div>
                  <div className="counter-content">
                    <h2 className="count" data-count="34">34</h2>
                    <p className="mb-0">Brands Helped</p>
                  </div>
                </div>
              </div>
              <div className="col-md-3 col-sm-6 col-6">
                <div className="counter">
                  <div className="counter-icon">
                    <em className="icon ti-basketball"></em>
                  </div>
                  <div className="counter-content">
                    <h2 className="count" data-count="145">+145</h2>
                    <p className="mb-0">Ongoing Task</p>
                  </div>
                </div>
              </div>
              <div className="col-md-3 col-sm-6 col-6">
                <div className="counter">
                  <div className="counter-icon">
                    <em className="icon ti-pencil-alt"></em>
                  </div>
                  <div className="counter-content">
                    <h2 className="count" data-count="437">+437</h2>
                    <p className="mb-0">Projects Done</p>
                  </div>
                </div>
              </div>
              <div className="col-md-3 col-sm-6 col-6">
                <div className="counter">
                  <div className="counter-icon">
                    <em className="icon ti-user"></em>
                  </div>
                  <div className="counter-content">
                    <h2 className="count" data-count="375">+375</h2>
                    <p className="mb-0">Satisfied Clients</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="section section-x team bg-bunker tc-bunker">
          <div className="container">
            <div className="row justify-content-center justify-content-sm-start gutter-vr-30px">
              <div className="col-lg-9 col-xl-3 text-center text-sm-left pl-sm-0">
                <div className="section-head section-head-col box-pad-sm">
                  <h5 className="heading-xs dash">Meet the team</h5>
                  <h2>People of behind work</h2>
                  <p className="lead mb-10">We’re always expanding hard working creators.</p>
                  <a href="dallas-dark-team.html" className="btn">Meet All Team</a>
                </div>
              </div>

              <div className="col-lg-4 col-xl-3 col-sm-4 col-10">
                <div className="team-single text-center">
                  <div className="team-image">
                    <img src={images.team} alt=""/>
                  </div>
                  <div className="team-content">
                    <h5 className="team-name">Andrew rover</h5>
                    <p>CEO, Genox</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-xl-3 col-sm-4 col-10">
                <div className="team-single text-center">
                  <div className="team-image">
                    <img src={images.team} alt=""/>
                  </div>
                  <div className="team-content">
                    <h5 className="team-name">Marina Bedi</h5>
                    <p>Developer, Genox</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-xl-3 col-sm-4 col-10">
                <div className="team-single text-center">
                  <div className="team-image">
                    <img src={images.team} alt=""/>
                  </div>
                  <div className="team-content">
                    <h5 className="team-name">Ajax Holder</h5>
                    <p>Head of Research, Genox</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
  
        <div className="section section-x tc-bunker">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-4 text-center">
                <div className="section-head section-sm">
                  <h5 className="heading-xs dash dash-both">Clients</h5>
                  <h2>Our friends who love to work with us</h2>
                </div>
              </div>
            </div>
            <div className="row justify-content-center gutter-vr-30px">
              <div className="col-sm-3 col-4">
                <div className="logo-item">
                  <img src={images.client} alt=""/>
                </div>
              </div>
              <div className="col-sm-3 col-4">
                <div className="logo-item">
                  <img src={images.client} alt=""/>
                </div>
              </div>
              <div className="col-sm-3 col-4">
                <div className="logo-item">
                  <img src={images.client} alt=""/>
                </div>
              </div>
              <div className="col-sm-3 col-4">
                <div className="logo-item">
                  <img src={images.client} alt=""/>
                </div>
              </div>
              <div className="col-sm-3 col-4">
                <div className="logo-item">
                  <img src={images.client} alt=""/>
                </div>
              </div>
              <div className="col-sm-3 col-4">
                <div className="logo-item">
                  <img src={images.client} alt=""/>
                </div>
              </div>
              <div className="col-sm-3 col-4">
                <div className="logo-item">
                  <img src={images.client} alt=""/>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="section section-cta bg-primary tc-light">
          <div className="container">
            <div className="row gutter-vr-30px align-items-center justify-content-between">
              <div className="col-lg-8 text-center text-lg-left">
                <div className="cta-text">
                  <h2>Like what you see? <strong> Let’s work </strong></h2>
                </div>
              </div>
              <div className="col-lg-4 text-lg-right text-center">
                <div className="cta-btn">
                  <a href="dallas-dark-contact.html" className="btn">Contact us</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default About
