import Constants from './constants';
/**
 * GET PROJECTS
 */
export const _getProjects = {
  pending: () => ({
    type: Constants.GET_PROJECTS_PENDING,
  }),
  success: data => ({
    type: Constants.GET_PROJECTS_SUCCESS,
    payload: data,
  }),
  error: err => ({
    type: Constants.GET_PROJECTS_ERROR,
    payload: err,
  }),
};

/**
 * GET CATEGORIES
 */
export const _getCategories = {
  pending: () => ({
    type: Constants.GET_CATEGORIES_PENDING,
  }),
  success: data => ({
    type: Constants.GET_CATEGORIES_SUCCESS,
    payload: data,
  }),
  error: err => ({
    type: Constants.GET_CATEGORIES_ERROR,
    payload: err,
  }),
};


/**
 * SET CURRENT PROJECT
 */
export const _setCurrentProject = {
  pending: () => ({
    type: Constants.SET_CURRENT_PROJECT_PENDING,
  }),
  success: data => ({
    type: Constants.SET_CURRENT_PROJECT_SUCCESS,
    payload: data,
  })
};
