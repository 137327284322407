import React, { Component } from 'react';
import {backgrounds, images} from "../../assets";
import Banner from "../../components/Banner";
import {NavLink} from "react-router-dom";

class Projects extends Component {
  
  componentDidMount() {
  }
  
  render() {
    return (
      <div>
        <Banner title={"WE HELP CLIENTS CREATE JOYFUL DIGITAL EXPERIENCES"} image={backgrounds.testbg}/>
        <div className="section section-x section-project tc-bunker" id="work">
          <div className="container">
            <div className="row">
              <div className="col-xl-6 col-lg-8 text-center text-md-left">
                <div className="section-head mtm-10">
                  <h2>Some highlights of work we've done for forward thinking clients.</h2>
                </div>
              </div>
              <div className="col-md-12">
                <ul className="project-filter filter-left project-md text-center text-sm-left">
                  <li className="active" data-filter="all">All</li>
                  <li data-filter="1">UI/UX</li>
                  <li data-filter="2">Branding</li>
                  <li data-filter="3">Development</li>
                  <li data-filter="4">Photography</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="project-area">
            <div className="container">
              <div className="row project project-s2 gutter-vr-30px" id="project1">
                <div className="col-md-4 col-sm-6 filtr-item" data-category="1">
                  <a href="dallas-dark-work-single.html">
                    <div className="project-item">
                      <div className="project-image">
                        <img src={images.team} alt=""/>
                      </div>
                      <div className="project-over">
                        <div className="project-content">
                          <h4>Landing Page </h4>
                          <p>UI/UX Design</p>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
                <div className="col-md-4 col-sm-6 filtr-item" data-category="1,2">
                  <a href="dallas-dark-work-single.html">
                    <div className="project-item">
                      <div className="project-image">
                        <img src={images.team} alt=""/>
                      </div>
                      <div className="project-over">
                        <div className="project-content">
                          <h4>Landing Page </h4>
                          <p>UI/UX Design</p>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
                <div className="col-md-4 col-sm-6 filtr-item" data-category="3,4">
                  <a href="dallas-dark-work-single.html">
                    <div className="project-item">
                      <div className="project-image">
                        <img src={images.team} alt=""/>
                      </div>
                      <div className="project-over">
                        <div className="project-content">
                          <h4>Landing Page </h4>
                          <p>UI/UX Design</p>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
                <div className="col-md-4 col-sm-6 filtr-item" data-category="1,2,3">
                  <a href="dallas-dark-work-single.html">
                    <div className="project-item">
                      <div className="project-image">
                        <img src={images.team} alt=""/>
                      </div>
                      <div className="project-over">
                        <div className="project-content">
                          <h4>Landing Page </h4>
                          <p>UI/UX Design</p>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
                <div className="col-md-4 col-sm-6 filtr-item" data-category="2">
                  <a href="dallas-dark-work-single.html">
                    <div className="project-item">
                      <div className="project-image">
                        <img src={images.team} alt=""/>
                      </div>
                      <div className="project-over">
                        <div className="project-content">
                          <h4>Landing Page </h4>
                          <p>UI/UX Design</p>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
                <div className="col-md-4 col-sm-6 filtr-item" data-category="3">
                  <a href="dallas-dark-work-single.html">
                    <div className="project-item">
                      <div className="project-image">
                        <img src={images.team} alt=""/>
                      </div>
                      <div className="project-over">
                        <div className="project-content">
                          <h4>Landing Page </h4>
                          <p>UI/UX Design</p>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
  
        <div className="section section-cta bg-primary tc-light">
          <div className="container">
            <div className="row gutter-vr-30px align-items-center justify-content-between">
              <div className="col-lg-8 text-center text-lg-left">
                <div className="cta-text">
                  <h2>Like what you see? <strong> Let’s work </strong></h2>
                </div>
              </div>
              <div className="col-lg-4 text-lg-right text-center">
                <div className="cta-btn">
                  <NavLink to="/contact" className="btn">Contact us</NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Projects
