import {connect} from "react-redux";
import {bindActionCreators} from 'redux';
import TeamState from '../../reducers/Team';
import ProjectState from '../../reducers/Projects';
import Home from './home'


function mapStateToProps(state, props) {
  console.log(state);
  return {
    team: state.Team.team,
    projects: state.Projects.projects,
    categories: state.Projects.categories
  };
}

function mapDispatchToProps(dispatch, props) {
  return bindActionCreators(
    {
      getTeam: TeamState.actions.getTeam,
      getProjects: ProjectState.actions.getProjects,
      setCurrentProject: ProjectState.actions.setCurrentProject
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);
