import React, {Component} from "react";
import {NavLink} from "react-router-dom";

export default class Banner extends Component {
  
  render() {
    let {title, subtitle, image, ctaText} = this.props;
    return (
      <div className="is-sticky is-shrink is-boxed header-s1" id="header">
        <div className="header-box">
          <div className="banner banner-inner tc-light">
            <div className="banner-block">
              <div className="container" style={{zIndex: 100}}>
                <div className="row">
                  <div className="col-md-9">
                    <div className="banner-content">
                      <h1 className="banner-heading m-0">{title}</h1>
                      <p className="lead m-0">{subtitle}</p>
                      {ctaText ? <NavLink to="/contact" className="btn">{ctaText}</NavLink> : null}
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-image" >
                <img src={image} alt="banner"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
  
}
