import React, {Component} from 'react';
import {backgrounds, images} from "../../assets";
import Banner from "../../components/Banner";

class Project extends Component {
  
  componentDidMount() {
  }
  
  render() {
    return (
      <div>
        <Banner title="Landing Page" subtitle="Client : Art Media Ltd" image={backgrounds.singleProject}/>
        <div className="section section-x">
          <div className="container">
            <div className="content">
              <div className="row gutter-vr-30px">
                <div className="col-xl-6 order-lg-last">
                  <div className="image-block">
                    <img src={images.clientLogo} alt=""/>
                  </div>
                </div>
                <div className="col-xl-6">
                  <div className="text-block  project-box-pad bg-primary tc-light h-full">
                    <h2>Landing Page Deisgn</h2>
                    <p>This communication platform makes it easy to share updates from any classroom around the world, empowering teachers, parents and students to connect and learn. Having reached more than 180 countries, it's clearly working.To maintain growth however was best-in-class with a growing community.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="section section-x pt-0 fw-3 tc-bunker">
          <div className="container">
            <div className="row gutter-vr-30px">
              <div className="col-md-4">
                <div className="text-box project-box-content project-box-pad bg-secondary h-full">
                  <p><strong>Client:</strong> Art Media LTD</p>
                  <p><strong>Budget:</strong> $200</p>
                  <p><strong>Category:</strong> UI/UX, Landing Page</p>
                  <p><strong>Date:</strong>September 19, 2019 </p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="text-box project-box-content h-full">
                  <h3>Challenge</h3>
                  <p>With a growing community of millions messaging and sharing photos and videos, Landing page needed
                    to ensure its apps were as high-quality, reliable and smoothly synced as could be.The company
                    brought us on board to accelerate development of both its iOS and Android mobile.</p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="text-box project-box-content h-full">
                  <h3>Solution</h3>
                  <p>We onboarded one Genox engineer to start, but Landing was so impressed with our seamless
                    integration into its business, that we quickly scaled to eight dedicated iOS and Android engineers.
                    Working with client, we helped deliver high-quality mobile development and code.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="section section-x pt-0">
          <div className="container">
            <div className="row gutter-vr-30px">
              <div className="col-md-6">
                <div className="image-block">
                  <img src={images.client} alt=""/>
                </div>
              </div>
              <div className="col-md-6">
                <div className="image-block">
                  <img src={images.client} alt=""/>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="section section-cta bg-primary tc-light">
          <div className="container">
            <div className="row gutter-vr-30px align-items-center justify-content-between">
              <div className="col-lg-8 text-center text-lg-left">
                <div className="cta-text">
                  <h2>Like what you see? <strong> Let’s work </strong></h2>
                </div>
              </div>
              <div className="col-lg-4 text-lg-right text-center">
                <div className="cta-btn">
                  <a href="dallas-dark-contact.html" className="btn">Contact us</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Project
