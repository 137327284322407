import React from 'react';
import {images} from "../../assets";

export const Preloader = () => {
  
  return (
    <div className="preloader preloader-dark preloader-dalas no-split">
      <span className="spinner spinner-alt">
        <img className="spinner-brand" src={images.logoWhite} alt=""/>
        </span>
    </div>
  )
}
