import React, {Component} from 'react';
import Banner from "../../components/Banner";
import {backgrounds} from "../../assets";
import {Preloader} from "../../components/Preloader";

class Contact extends Component {
  
  render() {
    return(
      <div>
        <Banner title={"BECAUSE WE ARE HUMAN AND VERY REAL"} subtitle={"YOU CAN CONTACT US"} image={backgrounds.header}/>
        <div className="section section-x tc-bunker">
          <div className="container">
            <div className="row">
              <div className="col-xl-4 col-lg-8">
                <div className="section-head">
                  <h5 className="heading-xs dash">Feel the form</h5>
                  <h2>Describe your project and leave us your contact info</h2>
                </div>
              </div>
            </div>
            <div className="row gutter-vr-30px">
              <div className="col-lg-4 order-lg-last">
                <div className="contact-text contact-text-s2 bg-secondary box-pad ">
                  <div className="text-box">
                    <h4>Genox Studio</h4>
                    <p className="lead">119 W 24th Street 4th New York, USA</p>
                  </div>
                  <ul className="contact-list">
                    <li>
                      <em className="contact-icon ti-mobile"></em>
                      <div className="conatct-content">
                        <a href="tel:19173303116">+1 917 330 3116</a>
                      </div>
                    </li>
                    <li>
                      <em className="contact-icon ti-email"></em>
                      <div className="conatct-content">
                        <a href="mailto:hello@this.work.com">hello@this.work.com</a>
                      </div>
                    </li>
                    <li>
                      <em className="contact-icon ti-map-alt"></em>
                      <div className="conatct-content">
                        <a href="#">Get Directions</a>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-8">
                <form className="genox-form form-dark mt-10" action="form/contact.php" method="POST">
                  <div className="form-results"></div>
                  <div className="row">
                    <div className="form-field col-md-6">
                      <input name="cf_name" type="text" placeholder="Your Name" className="input bdr-b required"/>
                    </div>
                    <div className="form-field col-md-6">
                      <input name="cf_email" type="email" placeholder="Your Email" className="input bdr-b required"/>
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-field col-md-6">
                      <input name="cf_address" type="text" placeholder="Your Address" className="input bdr-b required"/>
                    </div>
                    <div className="form-field col-md-6">
                      <input name="cf_company" type="text" placeholder="Your Compnay" className="input bdr-b required"/>
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-field col-md-6">
                      <input type="text" name="cf_date" className="input bdr-b datepicker"
                             placeholder="When do you want start?"/>
                    </div>
                    <div className="form-field form-select col-md-6">
                      <select name="cf_budget" className="form-control input-select bdr-b input required"
                              id="selectid_b">
                        <option>What your Budget</option>
                        <option>$100 - $200</option>
                        <option>$200 - $300</option>
                        <option>$300 - $400</option>
                        <option>$400 - $500</option>
                      </select>
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-field col-md-12">
                      <textarea name="cf_msg" placeholder="Briefly tell us about your project. "
                                className="input input-msg bdr-b required"></textarea>
                      <input type="text" className="d-none" name="form-anti-honeypot" defaultValue=""/>
                        <button type="submit" className="btn">Send Message</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Contact;
