import React from "react";
import {NavLink} from "react-router-dom";


export const WhatWeDo = () => {
  return(
      <div className="section section-x tc-bunker">
        <div className="container">
          <div className="row justify-content-between gutter-vr-30px">
            <div className="col-lg-4 col-xl-3 col-md-8">
              <div className="section-head section-head-col">
                <h5 className="heading-xs dash">What we do</h5>
                <h2>Innovations and impact</h2>
                <p className="lead">We put innovation in everything we do</p>
                <NavLink to="/services" className="btn">Discover More</NavLink>
              </div>
            </div>
            <div className="col-lg-8">
              <div className="row gutter-vr-30px gutter-vr-30px-res">
                <div className="col-sm-6">
                  <div className="feature">
                    <div className="feature-icon">
                      <em className="icon ti-panel"/>
                    </div>
                    <div className="feature-content">
                      <h3>Web Development</h3>
                      <p>Bla bla bla</p>
                      <NavLink to="/services" className="btn btn-arrow">Read More</NavLink>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="feature">
                    <div className="feature-icon">
                      <em className="icon ti-write"/>
                    </div>
                    <div className="feature-content">
                      <h3>Strategy</h3>
                      <p>From concept to execution, we are there for your product during this adventure.</p>
                      <NavLink to="/services" className="btn btn-arrow">Read More</NavLink>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="feature">
                    <div className="feature-icon">
                      <em className="icon ti-layers-alt"/>
                    </div>
                    <div className="feature-content">
                      <h3>Development</h3>
                      <p>We use the latest technology and we never stop learning.</p>
                      <NavLink to="/services" className="btn btn-arrow">Read More</NavLink>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="feature">
                    <div className="feature-icon">
                      <em className="icon ti-bar-chart-alt "/>
                    </div>
                    <div className="feature-content">
                      <h3>Analytics</h3>
                      <p>From concept to execution, we build your product and provide you services with scale and future development in our minds</p>
                      <NavLink to="/services" className="btn btn-arrow">Read More</NavLink>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  )
};
