import React from 'react';
import {images} from "../../assets";

export const NewsSection = () => {
  
  return (
    <div className="section section-x tc-bunker bg-secondary tc-grey section-news">
      <div className="container">
        <div className="row justify-content-center ">
          <div className="col-lg-8 text-center">
            <div className="section-head section-md">
              <h5 className="heading-xs dash dash-both">Latest news</h5>
              <h2>Software From Space</h2>
            </div>
          </div>
        </div>
        <div className="row gutter-vr-30px justify-content-sm-center">
          <div className="col-sm-8 col-md-4">
            <div className="post post-alt">
              <div className="post-thumb">
                <a href="texas-news-details.html">
                  <img src={images.header.project} alt="post"/>
                </a>
              </div>
              <div className="post-content">
                <p className="post-tag">DECEMBER 12, 2018</p>
                <h4><a href="texas-news-details.html">One of the Best UX Agencies in the World</a></h4>
              </div>
            </div>
          </div>
          <div className="col-sm-8 col-md-4">
            <div className="post post-alt">
              <div className="post-thumb">
                <a href="texas-news-details.html">
                  <img src={images.header.project} alt="post"/>
                </a>
              </div>
              <div className="post-content">
                <p className="post-tag">DECEMBER 10, 2018</p>
                <h4><a href="texas-news-details.html">5 Tricks To Help Boost Creativity in One Touch</a></h4>
              </div>
            </div>
          </div>
          <div className="col-sm-8 col-md-4">
            <div className="post post-alt">
              <div className="post-thumb">
                <a href="texas-news-details.html">
                  <img src={images.header.project} alt="post"/>
                </a>
              </div>
              <div className="post-content">
                <p className="post-tag">DECEMBER 08, 2018</p>
                <h4><a href="texas-news-details.html">Best tools for preperation final development</a></h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
