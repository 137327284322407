import * as api from './api';
import * as actions from './actionCreators';

export const getTeam = () => dispatch => {
  dispatch(actions._getTeam.pending());
  api
    .getTeam()
    .then( res => {
        dispatch(actions._getTeam.success(res.body));
      }
    )
    .catch(
      err => {
        dispatch(actions._getTeam.error(err));
      }
    );
};

