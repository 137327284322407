export const IS_DEVELOPMENT = process.env.NODE_ENV === 'development';
export const API_HOST = 'http://localhost:1337';
export const TIMEOUT = 10000;

export const API_ROUTES  = {
  projects: {
    get: "/projects",
    get_categories: "/categories"
  },
  team: {
    get: '/teams'
  }
};
