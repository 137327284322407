import React, {Component} from 'react'
import {backgrounds, images} from "../../assets/index";
import {NavLink} from "react-router-dom";

class Header extends Component {
  
  render() {
    return (
      <header className="is-sticky is-shrink is-boxed header-s1" id="header">
        <div className="header-box">
          <div className="banner tc-light">
            <div className="banner-block">
              <div className="container">
                <div className="row ">
                  <div className="col-md-10 col-xl-7 offset-xl-0">
                    <div className="banner-content">
                      <h1 className="banner-heading">Take your product on an adventure</h1>
                      <p className="lead lead-lg">We are a digital agency with a human approach</p>
                      <div className="banner-btn">
                        <NavLink to="/projects" className="menu-link btn banner-btn-white">Check Out Our Work</NavLink>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-image bg-image-header change-bg">
                <img src={backgrounds.header} alt="banner"/>
              </div>
            </div>
          </div>
        </div>
      </header>
    )
  }
}

export default Header;
