import React, {Component, Profiler} from 'react';

import Header from "../../components/Header";
import {WhatWeDo} from "../../components/WhatWeDo";
import {WhoWeAre} from "../../components/WhoWeAre";
import {AboutSection} from "../../components/AboutSection";
import {ProcessSection} from "../../components/ProcessSection";
import {WorkSection} from "../../components/WorkSection";
import {TestimonialsSection} from "../../components/TestimonialsSection";
import {TeamSection} from "../../components/TeamSection";
import {ClientsSection} from "../../components/ClientsSection";
import {NumbersSection} from "../../components/NumbersSection";
import {NewsSection} from "../../components/NewsSection";
import {CTASection} from "../../components/CtaSection";

class Home extends Component {
  
  componentDidMount() {
    this.props.getTeam();
    this.props.getProjects();
  }
  
  render() {
    let {team, projects, categories, match} = this.props;
    return (
      <div>
        <Header/>
        <WhatWeDo/>
        <WhoWeAre/>
        <AboutSection/>
        <ProcessSection/>
        <WorkSection projects={projects} categories={categories} match={match}/>
        <TestimonialsSection/>
        <TeamSection team={team}/>
        <ClientsSection/>
        <NumbersSection/>
        <NewsSection/>
        <CTASection/>
      </div>
    );
  }
}

export default Home
