import React from "react";
import {images} from "../../assets";
import {NavLink} from "react-router-dom";

export const NavBar = () => {
  
  return(
    <div>
      <header className="is-sticky is-shrink is-boxed header-s1" id="header">
        <div className="header-box">
          <div className="header-main">
            <div className="header-wrap">
              <div className="header-logo logo" style={{width: "200px"}}>
                <NavLink to="/" className="logo-link">
                  <img className="logo-light" src={images.header.logo} srcSet={images.header.logo} alt="logo"/>
                  <img className="logo-dark" src={images.header.logo} srcSet={images.header.logo} alt="logo"/>
                </NavLink>
              </div>
          
              <div className="header-nav-toggle">
                <a href="#" className="search search-mobile search-trigger">
                  <i className="icon ti-search "/>
                </a>
                <a href="#" className="navbar-toggle" data-menu-toggle="header-menu">
                  <div className="toggle-line">
                    <span/>
                  </div>
                </a>
              </div>
          
              <div className="header-navbar">
                <nav className="header-menu" id="header-menu">
                  <ul className="menu">
                    <li className="menu-item"><NavLink activeClassName={"active"} className="menu-link nav-link" to={"/"}>Home</NavLink></li>
                    <li className="menu-item"><NavLink activeClassName={"active"} className="menu-link nav-link" to={"/projects"}>Explorations</NavLink></li>
                    <li className="menu-item"><NavLink activeClassName={"active"} className="menu-link nav-link" to={"/services"}>Services</NavLink></li>
                    <li className="menu-item"><NavLink activeClassName={"active"} className="menu-link nav-link" to={"/about"}>About</NavLink></li>
                    <li className="menu-item"><NavLink activeClassName={"active"} className="menu-link nav-link" to="dallas-dark-news.html">Blog</NavLink></li>
                  </ul>
                  <ul className="menu-btns">
                    <li><NavLink to="/contact" className="btn btn-sm">Start A project</NavLink></li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </header>
    </div>
  )
}
