import {API_ROUTES} from '../../config';
import { get } from '../../utils/superagent';

export const getProjects = async () => {
  return await get(API_ROUTES.projects.get, {});
};

export const getCategories = async () => {
  return await get(API_ROUTES.projects.get_categories, {});
};
