// Images
import logo from "./images/logo/logo-color-small.png";
import logoWhite from "./images/logo-white.png";
import logoSfs from "./images/logo-sfs.png";
import project from "./images/project-c.jpg";
import whoweare from "./images/whoweare/bg-1.jpg";
import client from "./images/client-a.png";
import astronaut from "./images/anim_astronaut.png";
import team from "./images/team-a.jpg";
import clientLogo from "./images/client-dark-a.png";
import numbersBg from "./images/bg-b.jpg";
import moon from "./images/moon.jpeg";

// Backgrounds
import header from "./images/header/header-3.png";
import singleProject from "./images/single-project-banner.jpg";
import testbg from "./images/banner-sm-g.jpg";

export const icons = {
};

export const images = {
  header: {
    logo,
    project
  },
  client,
  logoSfs,
  astronaut,
  team,
  logoWhite,
  clientLogo
};

export const backgrounds = {
  header,
  whoweare,
  singleProject,
  numbersBg,
  testbg,
  moon
};
