import {API_HOST} from '../config';
export const getImage = (url) => {
  return `${API_HOST}${url}`
};

export const slugify  = (str) => {
  let slug = str.replace(/^\s+|\s+$/g, ''); // trim
  slug = slug.toLowerCase();
  
  // remove accents, swap ñ for n, etc
  const from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
  const to   = "aaaaeeeeiiiioooouuuunc------";
  for (let i=0, l=from.length ; i<l ; i++) {
    slug = slug.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
  }
  
  slug = slug.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
    .replace(/\s+/g, '-') // collapse whitespace and replace by -
    .replace(/-+/g, '-'); // collapse dashes
  
  return slug;
}

export const logAll = (params) => {
  console.log('--------------------- START LOG ALL ---------------------');
  return params.map((p) => {
    console.log('------------------------------------------');
    return console.log(`${p}` , p);
  })
};
