import React from 'react';
import {images} from "../../assets";

export const Footer = () => {
  
  return(
    <footer className="section footer bg-dark-alt tc-light footer-s1">
      <div className="container">
        <div className="row gutter-vr-30px">
          <div className="col-lg-3 col-sm-6">
            <div className="wgs">
              <div className="wgs-content">
                <div className="wgs-logo">
                  <a href="./">
                    <img src={images.logoWhite} srcSet={images.logoWhite} alt="logo"/>
                  </a>
                </div>
                <p>&copy; 2019. All rights reserved. Designed & Developed by <a href="#">Software From Space</a></p>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6">
            <div className="wgs">
              <div className="wgs-content">
                <h3 className="wgs-title">Company</h3>
                <ul className="wgs-menu">
                  <li><a href="dallas-dark-about.html">About us</a></li>
                  <li><a href="dallas-dark-about.html">Why Genox?</a></li>
                  <li><a href="dallas-dark-team.html">Meet the team</a></li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6">
            <div className="wgs">
              <div className="wgs-content">
                <h3 className="wgs-title">Services</h3>
                <ul className="wgs-menu">
                  <li><a href="dallas-dark-service-single.html">Digital Media</a></li>
                  <li><a href="dallas-dark-service-single.html">Strategy</a></li>
                  <li><a href="dallas-dark-service-single.html">Development</a></li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6">
            <div className="wgs">
              <div className="wgs-content">
                <h3 className="wgs-title">Get our staff</h3>
                <form className="genox-form" action="form/subscribe.php" method="POST">
                  <div className="form-results"></div>
                  <div className="field-group btn-inline">
                    <input name="s_email" type="email" className="input" placeholder="Your  Email"/>
                      <input type="text" className="d-none" name="form-anti-honeypot" defaultValue=""/>
                        <button type="submit" className="far fa-paper-plane button"></button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}
