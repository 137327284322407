import React, { Component } from 'react';

class NotFound extends Component {
  
  render() {
    return (
      <div className="section section-x page-extra-pd tc-bunker align-middle-md">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6 col-md-8 text-center">
              <div className="error-content">
                <span className="error-text-large">404</span>
                <h5>Opps! Why you’re here?</h5>
                <p>We are very sorry for inconvenience. It looks like you’re try to access a page that either has been
                  deleted or never existed.</p>
                <a href="index.html" className="btn">Back to Home</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default NotFound
