import React from "react";

export const ProcessSection = () => {
  
  return(
    <div className="section section-x bg-bunker tc-bunker">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-12 text-center">
            <div className="section-head section-sm">
              <h5 className="heading-xs dash dash-both">Our Process</h5>
              <h2>We make products with strategy</h2>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-4 col-md-6 text-center">
            <div className="feature feature-s2">
              <div className="feature-icon-box">
                <div className="feature-icon feature-icon-s3">
                  <em className="icon ti-clipboard"></em>
                </div>
                <div className="feature-heading feature-heading-s2">
                  <h3>Concept</h3>
                </div>
              </div>
              <div className="feature-content-s2">
                <p>We listen, research, ideate, marinate, present, and Duis aute irure dolor in reprehenderit in
                  voluptate.</p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 text-center">
            <div className="feature feature-s2">
              <div className="feature-icon-box">
                <div className="feature-icon feature-icon-s3">
                  <em className="icon ti-layers"></em>
                </div>
                <div className="feature-heading feature-heading-s2">
                  <h3>Production</h3>
                </div>
              </div>
              <div className="feature-content-s2">
                <p>The best crews with the most up-to-date gear and technologies capture your story. Duis aute irure
                  dolor.</p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 text-center">
            <div className="feature feature-s2">
              <div className="feature-icon-box">
                <div className="feature-icon feature-icon-s3">
                  <em className="icon ti-layout"></em>
                </div>
                <div className="feature-heading feature-heading-s2">
                  <h3>Post Production</h3>
                </div>
              </div>
              <div className="feature-content-s2">
                <p>Producers, editors, designers, and animators, shape and sculpt your video ‘til it’s ready for prime
                  time.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
