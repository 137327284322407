import React from 'react';
import {backgrounds, images} from "../../assets";

export const NumbersSection = () => {
  
  return(
    <div className="section section-x section-counter tc-light">
      <div className="container">
        <div className="row justify-content-between align-items-center gutter-vr-30px">
          <div className="col-md-5">
            <div className="text-box mrm-20">
              <h2>Our top minds make it happen with digital approach</h2>
              <p>Our award-winning design team and engineers with proficiency across multiple fields &
                specializations.</p>
            </div>
          </div>
          <div className="col-md-6">
            <div className="row no-gutters">
              <div className="col-6">
                <div className="counter counter-s2 counter-bdr border-bottom-0">
                  <div className="counter-icon">
                    <em className="icon ti-dropbox"></em>
                  </div>
                  <div className="counter-content counter-content-s2">
                    <h2 className="count" data-count="34">34</h2>
                    <p>Brands Helped</p>
                  </div>
                </div>
              </div>
              <div className="col-6">
                <div className="counter counter-s2 counter-bdr border-bottom-0">
                  <div className="counter-icon">
                    <em className="icon ti-basketball"></em>
                  </div>
                  <div className="counter-content counter-content-s2">
                    <h2 className="count" data-count="145">+145</h2>
                    <p>Ongoing Task</p>
                  </div>
                </div>
              </div>
              <div className="col-6">
                <div className="counter counter-s2 counter-bdr">
                  <div className="counter-icon">
                    <em className="icon ti-pencil-alt"></em>
                  </div>
                  <div className="counter-content counter-content-s2">
                    <h2 className="count" data-count="437">+437</h2>
                    <p>Projects Done</p>
                  </div>
                </div>
              </div>
              <div className="col-6">
                <div className="counter counter-s2 counter-bdr">
                  <div className="counter-icon">
                    <em className="icon ti-user"></em>
                  </div>
                  <div className="counter-content counter-content-s2">
                    <h2 className="mrm-4 count" data-count="375">+375</h2>
                    <p>Satisfied Clients</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-image bg-fixed">
        <img src={backgrounds.numbersBg} alt=""/>
      </div>
    </div>
  )
};
