import React from 'react';
import {images} from "../../assets";
import {getImage} from "../../utils";

export const TeamSection = ({team}) => {
  
  return(
    <div className="section section-x team bg-bunker tc-bunker">
      <div className="container">
        <div className="row justify-content-center justify-content-sm-start gutter-vr-30px">
          <div className="col-lg-9 col-xl-3 text-center text-sm-left pl-sm-0">
            <div className="section-head section-head-col box-pad-sm">
              <h5 className="heading-xs dash">Meet the team</h5>
              <h2>People of behind work</h2>
              <p className="lead">We’re always expanding hard working creators.</p>
              <a href="dallas-dark-team.html" className="btn">Meet All Team</a>
            </div>
          </div>
          {team.map((member, index) => (
            <div className="col-xl-3 col-sm-4" key={index}>
              <div className="team-single text-center">
                <div className="team-image">
                  <img src={getImage(member.picture.url)} alt=""/>
                </div>
                <div className="team-content">
                  <h5 className="team-name">{`${member.first_name} ${member.last_name}`}</h5>
                  <p>{member.position}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
