import React from "react";
import {images} from "../../assets";

export const ClientsSection = () => {
  
  return(
      <div className="section bg-dark-alt tc-bunker section-l">
        <div className="container">
          <div className="row justify-content-center ">
            <div className="col-lg-8 text-center">
              <div className="section-head section-md">
                <h5 className="heading-xs dash dash-both">Clients</h5>
                <h2 >Our friends who love to work with us</h2>
              </div>
            </div>
          </div>
          <div className="row justify-content-center gutter-vr-30px">
            <div className="col-sm-3 col-4">
              <div className="logo-item">
                <img src={images.clientLogo} alt=""/>
              </div>
            </div>
            <div className="col-sm-3 col-4">
              <div className="logo-item">
                <img src={images.clientLogo} alt=""/>
              </div>
            </div>
            <div className="col-sm-3 col-4">
              <div className="logo-item">
                <img src={images.clientLogo} alt=""/>
              </div>
            </div>
            <div className="col-sm-3 col-4">
              <div className="logo-item">
                <img src={images.clientLogo} alt=""/>
              </div>
            </div>
            <div className="col-sm-3 col-4">
              <div className="logo-item">
                <img src={images.clientLogo} alt=""/>
              </div>
            </div>
            <div className="col-sm-3 col-4">
              <div className="logo-item">
                <img src={images.clientLogo} alt=""/>
              </div>
            </div>
            <div className="col-sm-3 col-4">
              <div className="logo-item">
                <img src={images.clientLogo} alt=""/>
              </div>
            </div>
          </div>
        </div>
      </div>
  )
};
