const constants = {};

/* GET PROJECTS */
constants['GET_PROJECTS_PENDING'] = 'PROJECTS/GET_PROJECTS_PENDING';
constants['GET_PROJECTS_SUCCESS'] = 'PROJECTS/GET_PROJECTS_SUCCESS';
constants['GET_PROJECTS_ERROR'] = 'PROJECTS/GET_PROJECTS_ERROR';

/* GET CATEGORIES */
constants['GET_CATEGORIES_PENDING'] = 'PROJECTS/GET_CATEGORIES_PENDING';
constants['GET_CATEGORIES_SUCCESS'] = 'PROJECTS/GET_CATEGORIES_SUCCESS';
constants['GET_CATEGORIES_ERROR'] = 'PROJECTS/GET_CATEGORIES_ERROR';

/* SET CURRENT PROJECT */
constants['SET_CURRENT_PROJECT_PENDING'] = 'PROJECTS/SET_CURRENT_PROJECT_PENDING';
constants['SET_CURRENT_PROJECT_SUCCESS'] = 'PROJECTS/SET_CURRENT_PROJECT_SUCCESS';

export default constants;
