import React from "react";
import {images} from "../../assets";
import {getImage, slugify} from "../../utils";
import { NavLink } from "react-router-dom";

export const WorkSection = ({projects, categories, match}) => {
  console.log(match);
  return(
    
    <div className="section section-x section-project pb-0 tc-bunker" id="project">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-7 text-center">
            <div className="section-head">
              <h5 className="heading-xs dash dash-both">Fetured Work</h5>
              <h2>Our mission is to deliver remarkable experiences and superior results for our clients </h2>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-12">
            <ul className="project-filter project-md">
              <li className="active" data-filter="all">All</li>
              {categories.map((category, index) => (
                <li data-filter="1" key={index}>{category.name}</li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      <div className="project-area bg-secondary">
        <div className="row project project-v5 no-gutters" id="project1">
          {projects.map((project, index) => (
            <div className="col-sm-6 col-xl-3 filtr-item" data-category="1" key={index}>
              <NavLink to={`project/${slugify(project.title)}`}>
                <div className="project-item">
                  <div className="project-image">
                    <img src={project.thumbnail ? getImage(project.thumbnail.url) : images.header.project} alt=""/>
                  </div>
                  <div className="project-over">
                    <div className="project-content">
                      <h4>{project.title} </h4>
                      <p>{project.category.type}</p>
                    </div>
                  </div>
                </div>
              </NavLink>
            </div>
          ))}
        </div>
        <div className="project-area project-call section-m bg-bunker tc-light">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="d-lg-flex text-center text-lg-left align-items-center justify-content-between">
                  <h2 className="fw-4">Want to see our more creative work? </h2>
                  <NavLink to={"/projects"} className="btn">See All Work</NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
