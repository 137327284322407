import React from "react";
import {backgrounds} from "../../assets";

export const WhoWeAre = () => {
  return(
    <div className="section section-x section-block tc-bunker">
      <div className="container">
        <div className="row justify-content-end">
          <div className="col-lg-9">
            <div className="text-block feature-area bg-darker">
              <div className="section-head">
                <h5 className="heading-xs dash">Who We are</h5>
                <h2>Our designers and engineers know collaboration is the essence</h2>
              </div>
              <div className="row gutter-vr-40px">
                <div className="col-sm-6">
                  <div className="feature">
                    <div className="feature-icon feature-icon-s2">
                      <em className="icon ti-notepad"></em>
                    </div>
                    <div className="feature-content feature-content-s4">
                      <h4>Quality Products</h4>
                      <p>We provide quality products with top creative talent build brands that stand out. </p>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="feature">
                    <div className="feature-icon feature-icon-s2">
                      <em className="icon ti-bookmark-alt"></em>
                    </div>
                    <div className="feature-content feature-content-s4">
                      <h4>True Value</h4>
                      <p>It’s not just our track record the efficiency We increase true value talent to build.</p>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="feature">
                    <div className="feature-icon feature-icon-s2">
                      <em className="icon ti-bag"></em>
                    </div>
                    <div className="feature-content feature-content-s4">
                      <h4>Discover & Sharing</h4>
                      <p>Whether you are fortune we Discover & share products with top creative talent.</p>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="feature">
                    <div className="feature-icon feature-icon-s2">
                      <em className="icon ti-reload"></em>
                    </div>
                    <div className="feature-content feature-content-s4">
                      <h4>Build Faster</h4>
                      <p>Unlock opportunity by creating human-centered products to build faster.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-image">
        <img src={backgrounds.whoweare} alt=""/>
      </div>
    </div>
  )
}
