import constants from './constants';

const initialApi = {
  loading: false,
  error: false,
  success: false
};

const emptyState = {
  team: [],
  api: initialApi
};
const initialState = JSON.parse(localStorage.getItem('Team')) || emptyState;

export default (state = initialState, action = {}) => {
  switch (action.type) {
  
  case constants.GET_TEAM_PENDING : {
    return {
      ...state,
      api: {
        ...initialApi,
        loading: true
      }
    };
  }

  case constants.GET_TEAM_SUCCESS: {
    const team = action.payload;
    let newState = {
      ...state,
      team,
      api: initialApi
    };
    
    localStorage.setItem('Team', JSON.stringify(newState));
    
    return newState;
  }

  case constants.GET_TEAM_ERROR: {
    const { error } = action.payload;
    return {
      ...state,
      api: {
        ...initialApi,
        error
      }
    };
  }

  default:
    return state;
  }
};
