import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import Projects from '../reducers/Projects/reducer';
import Team from '../reducers/Team/reducer';

export default combineReducers({
  routing: routerReducer,
  Projects,
  Team
});
