import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom'

import * as serviceWorker from './serviceWorker';
import store from './redux/store';

// Root component
import Root from './modules/Root';

// Styles
import './assets/css/vendor.bundle.css?ver=141';
import './assets/css/style.css?ver=141';
import './assets/css/theme.css?ver=141';

// Slick
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Scripts
// import './assets/js/jquery.bundle.js?ver=141';
import './assets/js/scripts.js?ver=141';

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
        <Root />
    </BrowserRouter>
  </Provider>,
  document.getElementById('root')
);
serviceWorker.register();
