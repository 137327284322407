import React, { Component } from 'react';
import {backgrounds, images} from "../../assets";
import Banner from "../../components/Banner";
import {NavLink} from "react-router-dom";
import '../../assets/js/scripts.js?ver=141';

class Services extends Component {
  
  render() {
    console.log("MOUNTED");
    return (
      <div>
        <Banner title="We Specialize in Digital Solutions" image={backgrounds.testbg} ctaText={"Let's talk"}/>
  
        <div className="section section-x tc-bunker">
          <div className="container">
            <div className="row justify-content-between">
              <div className="col-md-5">
                <div className="section-head section-md res-m-btm">
                  <h5 className="heading-xs dash">OUR SERVICES</h5>
                  <h2>We help brands and companies stand out in the digital age.</h2>
                </div>
              </div>
              <div className="col-md-6">
                <div className="section-head section-md">
                  <div className="text-box">
                    <p className="lead">It’s not just our track record, the efficiency of our process and the quality of
                      our products. It’s the secret ingredient that makes it all happen: the bravery of our people</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row justify-content-center gutter-vr-30px">
              <div className="col-sm-6 col-xl-3">
                <div className="feature feature-s2 feature-s2-inner bdr">
                  <div className="feature-icon">
                    <em className="ti-panel"></em>
                  </div>
                  <div className="feature-content-s3">
                    <h3>Digital Media</h3>
                    <p>Seamless integration all digital media channels with advanced targeting full transparency.</p>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-xl-3">
                <div className="feature feature-s2 feature-s2-inner bdr">
                  <div className="feature-icon">
                    <em className="ti-write"></em>
                  </div>
                  <div className="feature-content-s3">
                    <h3>Strategy</h3>
                    <p>From concept to execute we create digital full campaigns from scratch and show you result.</p>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-xl-3">
                <div className="feature feature-s2 feature-s2-inner bdr">
                  <div className="feature-icon">
                    <em className="ti-layers-alt"></em>
                  </div>
                  <div className="feature-content-s3">
                    <h3>Design</h3>
                    <p>We use the latest technology and we never stop learning. usability devs work together.</p>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-xl-3">
                <div className="feature feature-s2 feature-s2-inner bdr">
                  <div className="feature-icon">
                    <em className="ti-bar-chart-alt"></em>
                  </div>
                  <div className="feature-content-s3">
                    <h3>Analytics</h3>
                    <p>It’s not just our track record the efficiency We increase true value talent to build.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
  
        <div className="section section-x tc-bunker">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-7 text-center">
                <div className="section-head section-md">
                  <h5 className="heading-xs">Our Process</h5>
                  <h2>We know the best results come from bold ideas. We enjoy what we do and we want you to too.</h2>
                </div>
              </div>
            </div>
            <div className="row justify-content-center gutter-vr-30px">
              <div className="col-lg-4 col-md-6 text-center">
                <div className="feature feature-s2 feature-s2-alt-inner bg-secondary">
                  <div className="icon-box">
                    <strong className="icon">01</strong>
                    <div className="icon-box-content">
                      <h6 className="tc-primary">Concept</h6>
                    </div>
                  </div>
                  <div className="feature-content">
                    <p>We listen, research, ideate, marinate, present, and launch Duis aute irure dolor in
                      voluptate.</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 text-center">
                <div className="feature feature-s2 feature-s2-alt-inner bg-secondary">
                  <div className="icon-box">
                    <strong className="icon">02</strong>
                    <div className="icon-box-content">
                      <h6 className="tc-primary">Production</h6>
                    </div>
                  </div>
                  <div className="feature-content">
                    <p>The best crews with the most up-to-date gear and technologies capture your story. </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 text-center">
                <div className="feature feature-s2 feature-s2-alt-inner bg-secondary">
                  <div className="icon-box">
                    <strong className="icon">03</strong>
                    <div className="icon-box-content">
                      <h6 className="tc-primary">Post Production</h6>
                    </div>
                  </div>
                  <div className="feature-content">
                    <p>Producers, editors, animators, shape and sculpt your video til it’s ready for prime time.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
    
          <div className="bg-image bg-fixed overlay-dark-light">
            <img src={backgrounds.testbg} alt=""/>
          </div>
        </div>
  
        <div className="section section-x tc-bunker">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-5 text-center">
                <div className="section-head section-md">
                  <h5 className="heading-xs dash dash-both">Clients</h5>
                  <h2>Our friends who love to work with us</h2>
                </div>
              </div>
            </div>
            <div className="row justify-content-center gutter-vr-40px">
              <div className="col-sm-3 col-4">
                <div className="logo-item">
                  <img src={images.client} alt=""/>
                </div>
              </div>
              <div className="col-sm-3 col-4">
                <div className="logo-item">
                  <img src={images.client} alt=""/>
                </div>
              </div>
              <div className="col-sm-3 col-4">
                <div className="logo-item">
                  <img src={images.client} alt=""/>
                </div>
              </div>
              <div className="col-sm-3 col-4">
                <div className="logo-item">
                  <img src={images.client} alt=""/>
                </div>
              </div>
              <div className="col-sm-3 col-4">
                <div className="logo-item">
                  <img src={images.client} alt=""/>
                </div>
              </div>
              <div className="col-sm-3 col-4">
                <div className="logo-item">
                  <img src={images.client} alt=""/>
                </div>
              </div>
              <div className="col-sm-3 col-4">
                <div className="logo-item">
                  <img src={images.client} alt=""/>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="section section-cta bg-primary tc-light">
          <div className="container">
            <div className="row gutter-vr-30px align-items-center justify-content-between">
              <div className="col-lg-8 text-center text-lg-left">
                <div className="cta-text">
                  <h2>Like what you see? <strong> Let’s work</strong></h2>
                </div>
              </div>
              <div className="col-lg-4 text-lg-right text-center">
                <div className="cta-btn">
                  <a href="dallas-dark-contact.html" className="btn">Contact us</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Services
