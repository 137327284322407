import React from 'react';

export const CTASection = () => {
  
  return(
    <div className="section section-cta bg-primary tc-light">
      <div className="container">
        <div className="row gutter-vr-30px align-items-center justify-content-between">
          <div className="col-lg-8 text-center text-lg-left">
            <div className="cta-text">
              <h2>Like what you see? <strong> Let’s work </strong></h2>
            </div>
          </div>
          <div className="col-lg-4 text-lg-right text-center">
            <div className="cta-btn">
              <a href="dallas-dark-contact.html" className="btn">Contact us</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
