import React, {Component} from 'react';

import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {withRouter, Switch, BrowserRouter as Router, Route} from 'react-router-dom'
import Home from "../pages/Home";
import NotFound from "../pages/NotFound";
import {Preloader} from "../components/Preloader";
import {NavBar} from "../components/NavBar";
import ProjectsState from '../reducers/Projects';
import Project from "../pages/Project/project";
import Projects from "../pages/Projects/projects";
import {Footer} from "../components/Footer";
import About from "../pages/About/about";
import Services from "../pages/Services/services";
import Contact from "../pages/Contact/contact";
import { createBrowserHistory } from 'history';

const history = createBrowserHistory();

class Root extends Component {
  
  constructor(props){
    super(props);

  }
  
  
  
  componentDidMount() {
    history.listen((location, action) => {
      console.log(action, location.pathname, location.state);
    });
  }
  
  render() {
    return (
        <Router>
          <div>
            <NavBar/>
            <Switch>
              <Route exact path="/" component={Home}/>
              <Route exact path="/projects" component={Projects}/>
              <Route exact path="/about" component={About}/>
              <Route exact path="/contact" component={Contact}/>
              <Route exact path="/services" component={Services}/>
              <Route exact path="/project/:title" component={Project}/>
              <Route component={NotFound}/>
            </Switch>
            <Footer/>
            <Preloader/>
          </div>
        </Router>
  
    );
  }
}

function mapStateToProps(state, props) {
  return {
    categories: state.Projects.categories
  };
}

function mapDispatchToProps(dispatch, props) {
  return bindActionCreators(
    {
      getCategories: ProjectsState.actions.getCategories
    },
    dispatch
  );
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Root))
