import constants from './constants';

const initialApi = {
  loading: false,
  error: false,
  success: false
};

const emptyState = {
  projects: [],
  categories: [],
  currentProject: {},
  api: initialApi
};

const initialState = JSON.parse(localStorage.getItem('Projects')) || emptyState;

export default (state = initialState, action = {}) => {
  switch (action.type) {
  
  case constants.GET_PROJECTS_PENDING : {
    return {
      ...state,
      api: {
        ...initialApi,
        loading: true
      }
    };
  }

  case constants.GET_PROJECTS_SUCCESS: {
    const projects = action.payload;
    let newState = {
      ...state,
      projects,
      api: initialApi
    };
    
    localStorage.setItem('Projects', JSON.stringify(newState));
    
    return newState;
  }

  case constants.GET_PROJECTS_ERROR: {
    const { error } = action.payload;
    return {
      ...state,
      api: {
        ...initialApi,
        error
      }
    };
  }
  
  case constants.GET_CATEGORIES_PENDING : {
    return {
      ...state,
      api: {
        ...initialApi,
        loading: true
      }
    };
  }

  case constants.GET_CATEGORIES_SUCCESS: {
    const categories = action.payload;
    return {
      ...state,
      categories,
      api: initialApi
    };
  }

  case constants.GET_CATEGORIES_ERROR: {
    const { error } = action.payload;
    return {
      ...state,
      api: {
        ...initialApi,
        error
      }
    };
  }
  
  case constants.SET_CURRENT_PROJECT_PENDING : {
    return {
      ...state,
      api: {
        ...initialApi,
        loading: true
      }
    };
  }

  case constants.SET_CURRENT_PROJECT_SUCCESS: {
    const currentProject = action.payload;
    return {
      ...state,
      currentProject,
      api: initialApi
    };
  }

  default:
    return state;
  }
};
